// AddUser.js
import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../firebaseConfig';
import { useAuth } from '../auth';
import { useNavigate } from 'react-router-dom'; // Importera useNavigate
import './styles/AddUser.css';

const AddUser = () => {
  const { currentUser, currentUserRole, setIsAddingUser } = useAuth();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: 'user',
    salesId: '',
    managerUid: '',
  });
  const [salesManagers, setSalesManagers] = useState([]);
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Skapa en navigate-instans

  useEffect(() => {
    const fetchSalesManagers = async () => {
      try {
        const q = query(collection(db, 'users'), where('role', '==', 'sales-manager'));
        const querySnapshot = await getDocs(q);
        const managers = querySnapshot.docs.map(doc => ({
          uid: doc.id,
          name: `${doc.data().firstName} ${doc.data().lastName}`,
        }));
        setSalesManagers(managers);
      } catch (error) {
        console.error('Error fetching sales managers:', error);
      }
    };

    fetchSalesManagers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (setIsAddingUser) setIsAddingUser(true);

    try {
      if (!userData.firstName || !userData.lastName || !userData.email || !userData.password) {
        setMessage('Alla fält är obligatoriska!');
        return;
      }

      if (!currentUser) {
        setMessage('Fel: Ingen användare är inloggad.');
        return;
      }

      const adminEmail = currentUser.email;
      const adminPassword = prompt("Ange ditt lösenord igen för att fortsätta");

      const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
      const user = userCredential.user;

      const newUser = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        role: userData.role,
        salesId: userData.salesId,
        managerUid: userData.managerUid || null,
      };

      await setDoc(doc(db, 'users', user.uid), newUser);

      await signInWithEmailAndPassword(auth, adminEmail, adminPassword);

      setMessage('Användare har lagts till framgångsrikt.');
      setUserData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: 'user',
        salesId: '',
        managerUid: '',
      });

      setTimeout(() => navigate('/add-user'), 2000); // Omdirigera efter 2 sekunder
    } catch (error) {
      console.error('Fel vid tillägg av användare:', error);
      setMessage('Ett oväntat fel uppstod. Försök igen.');
    } finally {
      if (setIsAddingUser) setIsAddingUser(false);
    }
  };

  return (
    <div className="add-user-container">
      <h1>{currentUserRole === 'admin' ? 'Lägg till användare' : 'Lägg till teammedlem'}</h1>
      <form onSubmit={handleSubmit} className="add-user-form">
        <div className="form-group">
          <label>Förnamn:</label>
          <input
            type="text"
            name="firstName"
            value={userData.firstName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Efternamn:</label>
          <input
            type="text"
            name="lastName"
            value={userData.lastName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>E-post:</label>
          <input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Lösenord:</label>
          <input
            type="password"
            name="password"
            value={userData.password}
            onChange={handleInputChange}
            required
          />
        </div>

        {(currentUserRole === 'admin' || currentUserRole === 'sales-manager') && (
          <div className="form-group">
            <label>Sales ID:</label>
            <input
              type="text"
              name="salesId"
              value={userData.salesId}
              onChange={handleInputChange}
              required
              placeholder="Ange Sales ID"
            />
          </div>
        )}

        <div className="form-group">
          <label>Roll:</label>
          <select
            name="role"
            value={userData.role}
            onChange={handleInputChange}
            required
          >
            <option value="user">User</option>
            <option value="sales-manager">Sales Manager</option>
            {currentUserRole === 'admin' && (
              <>
                <option value="admin">Admin</option>
                <option value="controller">Controller</option>
              </>
            )}
          </select>
        </div>

        {currentUserRole === 'admin' && (
          <div className="form-group">
            <label>Välj Sales Manager:</label>
            <select
              name="managerUid"
              value={userData.managerUid}
              onChange={handleInputChange}
            >
              <option value="">Ingen Sales Manager</option>
              {salesManagers.map((manager) => (
                <option key={manager.uid} value={manager.uid}>
                  {manager.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <button type="submit" disabled={setIsAddingUser}>
          {setIsAddingUser ? 'Laddar...' : currentUserRole === 'admin' ? 'Lägg till användare' : 'Lägg till teammedlem'}
        </button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default AddUser;