// ControllerStatistics.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './styles/ControllerStatistics.css';

const ControllerStatistics = () => {
  const [finalReports, setFinalReports] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Vanliga filter-states
  const [selectedSalesperson, setSelectedSalesperson] = useState('');
  const [salespersons, setSalespersons] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalSales, setTotalSales] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  // *** Nytt: manager-lista, men nu med namn
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');

  // För redigering
  const [editingItem, setEditingItem] = useState(null);
  const [editedShares, setEditedShares] = useState('');
  const [editedSales, setEditedSales] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));

        // 1) Skapa ett "uppslagsverk" av alla användare
        //    Format: { [uid]: "Förnamn Efternamn" }
        const userNameMap = {};

        // För att hålla reda på all försäljning
        const allSalesData = [];

        // Vi använder en set() för att hålla reda på unika manager-UIDs.
        // Sedan kan vi mappa dessa UID → manager-namn via userNameMap.
        const managerUidSet = new Set();

        // 2) Först loopar vi igenom snapshot för att skapa userNameMap.
        usersSnapshot.forEach((docSnap) => {
          const userData = docSnap.data();
          const uid = docSnap.id;

          const fullName = `${userData.firstName || ''} ${userData.lastName || ''}`.trim();
          userNameMap[uid] = fullName;
        });

        // 3) Nu loopar vi igenom alla users igen för att hämta deras sales.
        for (const userDoc of usersSnapshot.docs) {
          const userData = userDoc.data();
          const userId = userDoc.id;

          // ManagerUid hos denna user
          const userManagerUid = userData.managerUid || '';

          // Sättet du hämtar sales oförändrat:
          const salesCollectionRef = collection(db, `users/${userDoc.id}/sales`);
          const salesSnapshot = await getDocs(salesCollectionRef);

          salesSnapshot.forEach(saleDoc => {
            const saleData = saleDoc.data();
            allSalesData.push({
              ...saleData,
              name: userNameMap[userId] || 'Okänd säljare', // Namn via userNameMap
              userId: userId,
              saleId: saleDoc.id,
              date: saleData.signDate || 'N/A',
              organisation: saleData.company || 'N/A',
              customerName: saleData.name || 'N/A',
              shares: saleData.shares || 'N/A',
              sales: saleData.amount || 0,
              status: saleData.status || 'Pending',

              // Spara managerUid för denna user
              managerUid: userManagerUid
            });
          });

          if (userManagerUid) {
            managerUidSet.add(userManagerUid);
          }
        }

        // Sätt i state
        setFinalReports(allSalesData);
        setFilteredData(allSalesData);
        calculateTotalSales(allSalesData);

        // 4) Bygg säljare-listan (namn)
        const salespersonsSet = new Set(allSalesData.map(item => item.name));
        setSalespersons([...salespersonsSet]);

        // 5) Bygg kund-listan (organisation)
        const customersSet = new Set(allSalesData.map(item => item.organisation));
        setCustomers([...customersSet]);

        // 6) Bygg manager-listan, men nu med {uid, name}
        const managerArray = Array.from(managerUidSet).map((mgrUid) => {
          return {
            uid: mgrUid,
            name: userNameMap[mgrUid] || mgrUid // fallback om managern saknar namn
          };
        });
        setManagers(managerArray);

      } catch (error) {
        console.error("Fel vid hämtning av data:", error);
      }
    };

    fetchData();
  }, []);

  const calculateTotalSales = (data) => {
    const total = data.reduce((sum, item) => {
      const salesValue = item.sales || 0;
      return sum + parseFloat(salesValue);
    }, 0);
    setTotalSales(total);
  };

  const handleFilterChange = () => {
    let filtered = [...finalReports];

    // Filtrera på vald säljare
    if (selectedSalesperson) {
      filtered = filtered.filter(item => item.name === selectedSalesperson);
    }

    // Filtrera på vald kund
    if (selectedCustomer) {
      filtered = filtered.filter(item => item.organisation === selectedCustomer);
    }

    // Filtrera på vald status
    if (statusFilter) {
      filtered = filtered.filter(item => item.status === statusFilter);
    }

    // Filtrera på vald manager
    if (selectedManager) {
      // Här filtrerar vi de items vars managerUid matchar vald manager
      filtered = filtered.filter(item => item.managerUid === selectedManager);
    }

    // Filtrera på datumintervall
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filtered = filtered.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    }

    setFilteredData(filtered);
    calculateTotalSales(filtered);
  };

  const updateStatus = async (userId, saleId) => {
    try {
      const saleDocRef = doc(db, `users/${userId}/sales/${saleId}`);
      await updateDoc(saleDocRef, { status: 'betald' });

      setFinalReports(prevReports =>
        prevReports.map(item =>
          item.saleId === saleId && item.userId === userId
            ? { ...item, status: 'betald' }
            : item
        )
      );

      setFilteredData(prevData =>
        prevData.map(item =>
          item.saleId === saleId && item.userId === userId
            ? { ...item, status: 'betald' }
            : item
        )
      );

      alert("Statusen har uppdaterats till 'betald'.");
    } catch (error) {
      console.error("Fel vid uppdatering av status:", error);
      alert("Ett fel uppstod vid uppdatering av status.");
    }
  };

  const handleEditClick = (item) => {
    setEditingItem(item);
    setEditedShares(item.shares);
    setEditedSales(item.sales);
  };

  const handleSave = async () => {
    if (editingItem) {
      try {
        const saleDocRef = doc(db, `users/${editingItem.userId}/sales/${editingItem.saleId}`);
        await updateDoc(saleDocRef, { shares: editedShares, amount: editedSales });

        setFinalReports(prevReports =>
          prevReports.map(item =>
            item.saleId === editingItem.saleId && item.userId === editingItem.userId
              ? { ...item, shares: editedShares, sales: editedSales }
              : item
          )
        );

        setFilteredData(prevData =>
          prevData.map(item =>
            item.saleId === editingItem.saleId && item.userId === editingItem.userId
              ? { ...item, shares: editedShares, sales: editedSales }
              : item
          )
        );

        alert("Informationen har uppdaterats.");
        setEditingItem(null); // Stäng redigeringsrutan
      } catch (error) {
        console.error("Fel vid uppdatering:", error);
        alert("Ett fel uppstod vid uppdatering.");
      }
    }
  };

  return (
    <div className="statistics-container">
      <h1>Controller Statistik</h1>

      {/* Filtersektion */}
      <div className="filter-container">
        <div>
          <label>Välj Säljare:</label>
          <select value={selectedSalesperson} onChange={(e) => setSelectedSalesperson(e.target.value)}>
            <option value="">Alla</option>
            {salespersons.map((salesperson, index) => (
              <option key={index} value={salesperson}>{salesperson}</option>
            ))}
          </select>
        </div>

        <div>
          <label>Välj Uppdrag:</label>
          <select value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)}>
            <option value="">Alla</option>
            {customers.map((customer, index) => (
              <option key={index} value={customer}>{customer}</option>
            ))}
          </select>
        </div>

        <div>
          <label>Status:</label>
          <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="">Alla</option>
            <option value="Pending">Pending</option>
            <option value="betald">Betald</option>
          </select>
        </div>

        {/* Nytt dropdown för Manager, där vi visar manager-namn istället för UID */}
        <div>
          <label>Välj Team:</label>
          <select value={selectedManager} onChange={(e) => setSelectedManager(e.target.value)}>
            <option value="">Alla</option>
            {managers.map((manager, index) => (
              <option key={index} value={manager.uid}>
                {manager.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Startdatum:</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </div>
        <div>
          <label>Slutdatum:</label>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div>
        <button className="filter-button" onClick={handleFilterChange}>
          Filtrera
        </button>
      </div>

      {/* Totalsumma */}
      <div className="total-sales">
        <h3>Totalt Försäljning: {totalSales}</h3>
      </div>

      {/* Tabell för att visa resultat */}
      <div className="table-container">
        <table className="statistics-table">
          <thead>
            <tr>
              <th>Säljare</th>
              <th>Datum</th>
              <th>Organisation</th>
              <th>Kund</th>
              <th>Aktier</th>
              <th>Försäljning</th>
              <th>Status</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="8">Ingen data tillgänglig</td>
              </tr>
            ) : (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.date}</td>
                  <td>{item.organisation}</td>
                  <td>{item.customerName || 'N/A'}</td>
                  <td>{item.shares || 'N/A'}</td>
                  <td>{item.sales || 'N/A'}</td>
                  <td>{item.status}</td>
                  <td>
                    {item.status !== 'betald' && (
                      <button
                        onClick={() => updateStatus(item.userId, item.saleId)}
                        className="status-button"
                      >
                        Markera som betald
                      </button>
                    )}
                    <button
                      onClick={() => handleEditClick(item)}
                      className="edit-button"
                    >
                      Redigera
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Modal för redigering */}
      {editingItem && (
        <div className="edit-modal">
          <div className="edit-modal-content">
            <h3>Redigera Information</h3>
            <label>
              Aktier:
              <input
                type="number"
                value={editedShares}
                onChange={(e) => setEditedShares(e.target.value)}
              />
            </label>
            <label>
              Försäljning:
              <input
                type="number"
                value={editedSales}
                onChange={(e) => setEditedSales(e.target.value)}
              />
            </label>
            <button onClick={handleSave} className="save-button">
              Spara
            </button>
            <button onClick={() => setEditingItem(null)} className="cancel-button">
              Avbryt
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ControllerStatistics;