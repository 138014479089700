// auth.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedInManually, setIsLoggedInManually] = useState(false); // Ny variabel för att styra inloggning
  const [isAddingUser, setIsAddingUser] = useState(false); // Lägg till isAddingUser-tillstånd

  const fetchUserData = async (user) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data fetched from Firestore:', userData);

        if (['user', 'admin', 'sales-manager', 'controller'].includes(userData.role)) {
          setCurrentUser({ ...user, role: userData.role });
          setCurrentUserRole(userData.role);
          console.log("Användarroll:", userData.role);
        } else {
          console.log('Ingen giltig roll hittades för användaren.');
          setCurrentUserRole(null);
        }
      } else {
        console.log('Användardokument saknas i Firestore.');
      }
    } catch (error) {
      console.error('Fel vid hämtning av användardata:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("onAuthStateChanged har anropats");
      if (user && isLoggedInManually) {
        setCurrentUser(user);
        await fetchUserData(user);
      } else {
        setCurrentUser(null);
        setCurrentUserRole(null);
      }
      setIsLoading(false);
    });

    return unsubscribe;
  }, [isLoggedInManually]);

  if (isLoading) {
    return <div>Laddar användardata...</div>;
  }

  return (
    <AuthContext.Provider value={{
      currentUser,
      currentUserRole,
      setIsLoggedInManually,
      isAddingUser, // Exponera isAddingUser
      setIsAddingUser, // Exponera setIsAddingUser
    }}>
      {children}
    </AuthContext.Provider>
  );
};